import "intersection-observer"; // polyfill - should be imported once before all
import "url-polyfill";          // polyfill - should be imported once before all
import "whatwg-fetch";          // polyfill - should be imported once before all

import AudioHandler from "./Analytics/Handlers/AudioHandler";
import Autoplay from "./Video/Autoplay";
import ButtonClickHandler from "./Analytics/Handlers/ButtonClickHandler";
import ClickLinkHandler from "./Analytics/Handlers/ClickLinkHandler";
import ClickLoadMoreButtonHandler from "./Analytics/Handlers/ClickLoadMoreButtonHandler";
import ClickRegisterNewsletter from "./Analytics/Handlers/ClickRegisterNewsletter";
import ClickTracker from "./DocumentTracker/ClickTracker";
import DeviceDetector from "./Utils/DeviceDetector";
import DeviceInstanceIdManager from "./User/DeviceInstanceIdManager";
import HandlersManager from "./Analytics/HandlersManager";
import MediaPlayerManager from "./Media/MediaPlayerManager";
import ModuleManager from "./ModuleLoader/ModuleManager";
import MostRecent from "./MostRecent/MostRecent";
import MostRecentLoadMoreRepository from "./MostRecent/MostRecentLoadMoreRepository";
import NavigationLayout from "./Layout/NavigationLayout";
import NewsletterHandler from "./Analytics/Handlers/NewsletterHandler";
import RightColumnManager from "./Layout/RightColumnManager";
import ScrolledToBeginOfBlockHandler from "./Analytics/Handlers/ScrolledToBeginOfBlockHandler";
import ScrolledToBlockItemHandler from "./Analytics/Handlers/ScrolledToBlockItemHandler";
import ScrolledToStoryContentBlockDepthHandler from "./Analytics/Handlers/ScrolledToStoryContentBlockDepthHandler";
import SocialShareHandler from "./Analytics/Handlers/SocialShareHandler";
import TimeSpentHandler from "./Analytics/Handlers/TimeSpentHandler";
import TrendingStats from "./Trending/TrendingStats";
import User from "./User/User";
import VideoComponent from "./Video/VideoComponent";
import VideoHandler from "./Analytics/Handlers/VideoHandler";
import VideoImaOptionsBuilder from "./Video/VideoImaOptionsBuilder";
import WebPart from "./Layout/WebPart";
import {detect} from "detect-browser";

import layout from "./Layout/desktopLayout";
import sha1 from "sha1";

export class App {

    _imaVideoOptions = [];

    _globals = {};

    _mediaPlayers = new MediaPlayerManager();
    get mediaPlayers() {
        return this._mediaPlayers;
    }

    /**
     * @type DeviceInstanceIdManager
     * @private
     */
    _deviceInstanceId = null;
    /**
     * @public
     * @returns {DeviceInstanceIdManager}
     */
    get deviceInstanceId() {
        if (this._deviceInstanceId == null) {
            this._deviceInstanceId = new DeviceInstanceIdManager();
        }

        return this._deviceInstanceId;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @public
     * @param obj {obj}
     */
    set globals(obj) {
        this._globals = obj;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @public
     */
    get globals() {
        return Object.assign(this._globals, {
            userId: this._user.anonymousUUID
        });
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * For QA. Easy access to IMA video options & Pub tags. For testing.
     * Example : nuglif.ngApp.imaVideoOptions[0].adTagUrl
     * @public
     * @returns {Array}
     */
    get imaVideoOptions() {
        return this._imaVideoOptions;
    }

    get user() {
        return this._user;
    }

    /**
     * @type {HandlersManager}
     * @private
     */
    _analyticsManager;

    _domWindow;
    get window() {
        return this._domWindow;
    }

    _domDocument;
    get document() {
        return this._domDocument;
    }

    _trendingStats;
    get trendingStats() {
        return this._trendingStats;
    }

    /**
     * @param {object} window
     */
    constructor(window) {
        this._user = new User(this.deviceInstanceId);
        this._domWindow = window;
        this._domDocument = window.document;

        if (this._domWindow.ngAppGlobals) {
            this._globals = this._domWindow.ngAppGlobals;
        }

        this._domWindow.googletag = this._domWindow.googletag || { cmd: [] };

        this._domDocument.addEventListener("DOMContentLoaded", this.boot.bind(this));
    }

    boot() {

        const browser = detect();
        if (browser) {
            this._domDocument.body.classList.add(browser.name);
        }

        this._bootLayoutPosition();
        this._doFinalLayoutPositioningWhenWindowHasFinishedRendering();

        this.bootAdmin();
        new NavigationLayout(this._domDocument, this._domWindow).boot();
        new RightColumnManager(".mainContent aside.col .webparts", "#rowMostRecent", this._domWindow);
        this.registerMediaPlayers();

        this._analyticsManager = new HandlersManager(this._domWindow, this._user);

        (new ModuleManager(this._domWindow, this._analyticsManager)).load();

        this._analyticsManager.addHandlers([
            new AudioHandler(),
            new NewsletterHandler(),
            new TimeSpentHandler("desktop"),
            new ScrolledToStoryContentBlockDepthHandler("[data-location=story]"),
            new ScrolledToBlockItemHandler("[data-location=mostRecents]", "mostRecents", "article", (node) => node.querySelector("a")),
            new ScrolledToBeginOfBlockHandler("[data-location=footer]", "footer"),
            new ScrolledToBeginOfBlockHandler("[data-location=relatedStories]", "relatedStories"),
            new VideoHandler(),
            new SocialShareHandler(".socialShare li"),
            new ClickLoadMoreButtonHandler(".mostRecentList__loadMoreBtn"),
            new ClickRegisterNewsletter("input.nl_box-wrapper-form-submit"),
            new ClickLinkHandler(),
            new ButtonClickHandler(".simpleButton"),
            new ButtonClickHandler(".newsletterPost__preview-button"),
        ]);

        this._analyticsManager.startTracking();
        new ClickTracker(this._domWindow, this._user);
        this._bootMostRecentLoadMoreButton();

        this._trendingStats = new TrendingStats();
    }

    _bootMostRecentLoadMoreButton() {
        const repository = new MostRecentLoadMoreRepository(this._domDocument);
        const mostRecent = new MostRecent(this._domDocument, repository);
        mostRecent.trackLoadMore();
    }

    _doFinalLayoutPositioningWhenWindowHasFinishedRendering() {
        this._domWindow.onload = () => {
            this._bootLayoutPosition();
        };
    }

    _bootLayoutPosition() {
        layout.setTopPosition();
    }

    /**
     * @returns {boolean}
     */
    bootAdmin() {
        const adminJsAndCssWebpartElement = this._domDocument.querySelector('.webpart[data-webpart-type="admin"]');

        if (adminJsAndCssWebpartElement === null) {
            return false;
        }

        new WebPart(
            this._domDocument,
            adminJsAndCssWebpartElement,
        ).load();
    }

    registerMediaPlayers() {

        let deviceInstanceId;
        let pageContainAutoplayVideo = false;
        this._deviceInstanceId.get(function(id) {deviceInstanceId = id;});

        let baseOptions = {
            network: this.globals.network,
            topLevelAdUnit: this.globals.topLevelAdUnit,
            adUnit: this.globals.adUnit,
            location: window.location,
            isShowCountdown: !DeviceDetector.isTablet(),
            userAgent: navigator.userAgent,
            storyAdsKeyValues: this.globals.storyAdsKeyValues,
            pageAdsKeyValues: this.globals.pageAdsKeyValues,
            deviceInstanceIdHash: sha1(deviceInstanceId),
            readerIdHash: localStorage.getItem("lp.ads.readerIdHash"),
            optableValues: typeof optable !== "undefined" ? optable?.instance?.targetingKeyValuesFromCache?.() || {} : {},
        };

        this._domDocument.querySelectorAll(".visual video, .videoModule video").forEach((video) => {
            let videoImaOptions = null;
            let shouldAutoplay;

            if ("false" === video.dataset.videoShouldSkipPub) {
                videoImaOptions = new VideoImaOptionsBuilder(Object.assign({}, baseOptions, {
                    elementId: video.id,
                    videoPosition: parseInt(video.dataset.videoPosition, 10),
                    videoDuration: parseInt(video.dataset.videoDuration, 10)
                })).getOptions();
                this._imaVideoOptions.push(videoImaOptions);
            }

            const autoPlay = new Autoplay(
                this._domWindow,
                video.dataset.autoplaySetting,
                false,
                video.dataset.isHeader === "true",
                !!document.querySelector('[data-location="dossierHeader"]'),
            );

            if (pageContainAutoplayVideo) {
                shouldAutoplay = false;
            } else {
                shouldAutoplay = autoPlay.shouldAutoplay();
            }

            this._mediaPlayers.push(new VideoComponent(
                video,
                videoImaOptions,
                shouldAutoplay
            ));

            if (shouldAutoplay) {
                pageContainAutoplayVideo = true;
            }
        });

        this._domDocument.querySelectorAll(".audioModule").forEach((audioPlayer) => {
            import(/* webpackChunkName: "AudioComponent" */ "./Media/AudioComponent").then(module => {
                const AudioComponent = module.default;
                this._mediaPlayers.push(new AudioComponent(audioPlayer));
            });
        });
    }
}

export const ngApp = new App(window);
