let cachedStatus = null;

export async function isAdblockActive() {
    if (cachedStatus !== null) return cachedStatus;

    const primaryTest = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    const fallbackTest = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";

    try {
        await fetch(primaryTest, { method: "HEAD", mode: "no-cors" });
        cachedStatus = false;
    } catch {
        try {
            await fetch(fallbackTest, { method: "HEAD", mode: "no-cors" });
            cachedStatus = false;
        } catch {
            cachedStatus = true;
        }
    }

    return cachedStatus;
}