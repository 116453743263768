export default class MostRecent {

    #document;
    #repository;
    #initialQty = 16;
    #getMoreIncrementQty = 8;
    #clickMax = 5;
    #clickMaxPodcast = 30;

    constructor(document, repository) {
        this.#document = document;
        this.#repository = repository;
    }

    trackLoadMore = () => {
        this.#document.querySelectorAll(".mostRecentList__loadMoreBtn").forEach(button => {
            this.#initButton(button);
        });
    };

    #initButton = button => {
        this.#removeLoadMoreButtonIfInitialCountIsLessThanDefault(button);
        button.addEventListener("click", async() => {
            await this.#handleClick(button);
        });
    };

    #handleClick = async button => {
        const lastDisplayedStoryId = this.#getLastDisplayedStoryId(button);
        const currentStoryId = this.#getCurrentStoryId(button);
        const elements = await this.#repository.getMore(this.#getUri(button), this.#getMoreIncrementQty, currentStoryId, lastDisplayedStoryId);
        const wrapper = this.#document.querySelector(".mostRecentList__list");

        for (let i = 0; i < elements.length; i++) {
            wrapper.appendChild(elements[i]);
        }

        this.#removeLoadMoreButtonIfNoMoreStoriesToDisplay(button);
        this.#removeLoadMoreButtonIfMaxClickReached(button);
    };

    #getUri = button => {
        return button.dataset.uri;
    };

    #getCurrentStoryId = button => {
        return button.dataset.currentStory;
    };

    #getLastDisplayedStoryId = button => {
        return Array.from(button.parentElement.querySelectorAll("article:last-of-type [data-target-type=story], article:last-of-type [data-target-type=dossier]"))
            .pop()
            .dataset
            .targetId;
    };

    #removeLoadMoreButtonIfMaxClickReached = button => {
        let clickMax = button.dataset.pageType === "podcast" ? this.#clickMaxPodcast : this.#clickMax;
        let clickCount = parseInt(button.dataset.clickCount);
        button.setAttribute("data-click-count", ++clickCount);

        if (button.dataset.clickCount >= clickMax) {
            button.remove();
        }
    };

    #removeLoadMoreButtonIfInitialCountIsLessThanDefault = button => {
        const articleCount = button.parentElement.querySelectorAll("article").length;
        if (articleCount < this.#initialQty) {
            button.remove();
        }
    };

    #removeLoadMoreButtonIfNoMoreStoriesToDisplay = button => {
        if (this.#repository.endOfListReached()) {
            button.remove();
        }
    };
}
